/* COMPONENTS */
import Banner from "../components/banner";
import AboutDinesh from "../components/about";
import FeaturedListings from "../components/featured-listings";
import Services from "../components/services";
import Testimonials from "../components/testimonials";
import Counters from "../components/counters";

const Home = () => {
  return (
    <>
      <section className="homepage">
        {/* BANNER */}
        <Banner />

        {/* ABOUT Dinesh Trambadia */}
        <AboutDinesh />

        {/* COUNTERS */}
        <Counters />

        {/* FEATURED LISTINGS */}
        <FeaturedListings />

        {/* SERVICES */}
        <Services />

        {/* INSTAGRAM WALL
        <InstagramWall /> */}

        {/* TESTIMONIALS */}
        <Testimonials />

        {/* BLOGS */}
        {/* <Blogs /> */}
      </section>
    </>
  );
};

export default Home;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

/* LAYOUT IMPORTS */
import Layout from "../layout/index";

/* PAGES IMPORT */
import Home from "../pages/index";
import ContactUs from "../pages/contact-me";
import Blogs from "../pages/blogs";
import BlogDetail from "../pages/blogs/blog-detail";
import HomeEvaluation from "../pages/home-evaluation";
import PropertyDetails from "../pages/active-properties/details";
import ResidentialBuying from "../pages/residential/buying";
import ResidentialSelling from "../pages/residential/selling";
import ResidentialRenting from "../pages/residential/renting";
import CommercialBuying from "../pages/commercial/buying";
import CommercialRenting from "../pages/commercial/renting";
import CommercialSelling from "../pages/commercial/selling";
import IndustrialBuying from "../pages/industrial/buying";
import IndustrialSelling from "../pages/industrial/selling";
import IndustrialRenting from "../pages/industrial/renting";
import PartnerWithUs from "../pages/partner-with-us";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const WebsiteRoutes = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <div className="app">
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/contact-me"
              element={
                <Layout>
                  <ContactUs />
                </Layout>
              }
            />
            <Route
              path="/blogs"
              element={
                <Layout>
                  <Blogs />
                </Layout>
              }
            />
            <Route
              path="/blog-detail/:id"
              element={
                <Layout>
                  <BlogDetail />
                </Layout>
              }
            />
            <Route
              path="/home-evaluation"
              element={
                <Layout>
                  <HomeEvaluation />
                </Layout>
              }
            />
            <Route
              path="/property-details/:id"
              element={
                <Layout>
                  <PropertyDetails />
                </Layout>
              }
            />
            <Route
              path="/buying-residential"
              element={
                <Layout>
                  <ResidentialBuying />
                </Layout>
              }
            />
            <Route
              path="/selling-residential"
              element={
                <Layout>
                  <ResidentialSelling />
                </Layout>
              }
            />
            <Route
              path="/renting-residential"
              element={
                <Layout>
                  <ResidentialRenting />
                </Layout>
              }
            />
            <Route
              path="/buying-commercial"
              element={
                <Layout>
                  <CommercialBuying />
                </Layout>
              }
            />
            <Route
              path="/renting-commercial"
              element={
                <Layout>
                  <CommercialRenting />
                </Layout>
              }
            />
            <Route
              path="/selling-commercial"
              element={
                <Layout>
                  <CommercialSelling />
                </Layout>
              }
            />
            <Route
              path="/buying-industrial"
              element={
                <Layout>
                  <IndustrialBuying />
                </Layout>
              }
            />
            <Route
              path="/renting-industrial"
              element={
                <Layout>
                  <IndustrialRenting />
                </Layout>
              }
            />
            <Route
              path="/selling-industrial"
              element={
                <Layout>
                  <IndustrialSelling />
                </Layout>
              }
            />
            <Route
              path="/partner-with-us"
              element={
                <Layout>
                  <PartnerWithUs />
                </Layout>
              }
            />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default WebsiteRoutes;

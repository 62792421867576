import { motion } from "framer-motion";

const TestimonialBox = (props) => {
  return (
    <div className="testimonial-box">
      <h5>{props.title}</h5>
      <p>{props.description}</p>
      <div className="testimonial-profile">
        {/* <img src={props.userProfile} alt={props.name} /> */}
        <div className="profile-details">
          <h5>{props.name}</h5>
          <p>{props.userRole}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="testimonials"
    >
      <div className="container">
        <div className="heading">
          <h2>WHAT CLIENTS SAY ABOUT ME</h2>
          <h5>
            Read what our satisfied clients have to say about their experience
            working with me.
          </h5>
        </div>
        <div className="row mt-2 gy-4 justify-content-center">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Best Selling Experience"
              description="Dinesh Trambadia's impressive knowledge and expertise facilitated a seamless home sale. He exceeded expectations, providing invaluable support and trust throughout. I'm enthusiastic about using and recommending him to others."
              name="Aarav Sharma"
              userProfile="https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Seller"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Great Experience"
              description="Dinesh Trambadia assisted me in selling my home and finding a rental property. He attentively addressed our needs, displaying professionalism and ethics throughout both processes. I'll definitely recommend him to others."
              name="Arjun Singhania"
              userProfile="https://images.unsplash.com/photo-1537511446984-935f663eb1f4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Buyer"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Easy Buying Experience"
              description="I recently bought a home thanks to Dinesh Trambadia! He replied with answers very quickly and worked very hard with the Escrow company to expedite the process. Frank is very knowledgeable of the real estate business."
              name="Diya Patel"
              userProfile="https://images.unsplash.com/photo-1580894732444-8ecded7900cd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Buyer"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Buying and Selling with Ease"
              description="Dinesh Trambadia's diverse expertise impressively includes selling my business, providing exceptional flyers and real estate advice, securing loans, and selling a rental house above expectations. His honesty, integrity, and hard work make him trustworthy and invaluable."
              name="Naina Chatterjee"
              userProfile="https://images.unsplash.com/photo-1607746882042-944635dfe10e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Buyer and Seller"
            />
          </div>
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <TestimonialBox
              title="Amazin to work with"
              description="Dinesh Trambadia's diverse expertise, assisting straight talk for 15+ years, finding suitable homes at fair prices for under-served populations. He goes above and beyond, ensuring that we are well-informed and cared for, making him our trusted go-to for real estate."
              name="Divya Malhotra"
              userProfile="https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80"
              userRole="Real Estate Buyer"
            />
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Testimonials;

import { Nav, NavDropdown, Navbar, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";

/* IMAGES */
import JayHousingLogo from "../assets/jay-housing-agency-logo.webp";

/* ICONS */
import { IoMail } from "react-icons/io5";
import { FaWhatsapp } from "react-icons/fa";

/* REDUX */
import actions from "../redux/actions/userAction";
import { useDispatch } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  return (
    <header className={`header dark`}>
      <div className="top-header">
        <div className="container">
          <div className="header-links">
            <div className="left-section"></div>
            <div className="right-section">
              <a
                href="mailto:jayhousingagency@gmail.com"
                className="social-media-box"
              >
                <IoMail color="#FFF" size="18" />
                <p>jayhousingagency@gmail.com</p>
              </a>
              <div className="phone-whatsapp">
                <a href="tel:+919322656348" className="social-media-box">
                  <FaWhatsapp color="#FFF" size="18" />
                  <p>+91 9322656348</p>
                </a>
                <a href="tel:+919773388720" className="social-media-box">
                  <FaWhatsapp color="#FFF" size="18" />
                  <p>+91 9773388720</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header-wrap">
        <div className="container">
          <div className="header-links">
            <Link to="/">
              <div className="logo-box">
                <img
                  src={JayHousingLogo}
                  className="company-logo"
                  alt="Jay Housing Agency Inc"
                />
              </div>
            </Link>
            <div className="navigation-links">
              <Navbar expand="xl">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Offcanvas id="offcanvas-expand-lg" placement="end">
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvas-expand-lg">
                      <Link to="/">
                        <div className="logo-box">
                          <img src={JayHousingLogo} alt="Jay Housing Agency" />
                        </div>
                      </Link>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="main-menu">
                      <Nav.Link href="/" className="menu-link">
                        Home
                      </Nav.Link>
                      <NavDropdown title="Residential">
                        <NavDropdown.Item
                          href="/buying-residential"
                          className="dropdown-link"
                        >
                          Buying
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="/selling-residential"
                          className="dropdown-link"
                        >
                          Selling
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="/renting-residential"
                          className="dropdown-link"
                        >
                          Renting
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Commercial">
                        <NavDropdown.Item
                          href="/buying-commercial"
                          className="dropdown-link"
                        >
                          Buying
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="/selling-commercial"
                          className="dropdown-link"
                        >
                          Selling
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="/renting-commercial"
                          className="dropdown-link"
                        >
                          Renting
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown title="Industrial">
                        <NavDropdown.Item
                          href="/buying-industrial"
                          className="dropdown-link"
                        >
                          Buying
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="/selling-industrial"
                          className="dropdown-link"
                        >
                          Selling
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          href="/renting-industrial"
                          className="dropdown-link"
                        >
                          Renting
                        </NavDropdown.Item>
                      </NavDropdown>
                      {/* <Nav.Link href="/partner-with-us" className="menu-link">
                        Partner with us
                      </Nav.Link> */}
                      <Nav.Link
                        onClick={() => {
                          dispatch(
                            actions.setContactUsHeader(
                              "Fill out the details to book an appointment"
                            )
                          );
                        }}
                        href="/contact-me"
                        className="menu-link"
                      >
                        Contact Me
                      </Nav.Link>
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Navbar>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};

const IndustrialRenting = () => {
  const categoryId = "65ca26086b0475e5c6a2e303"; // Residential
  const subCategoryId = "65ca27175f4799bc44a02ba0"; // Buying

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/commercial-video.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>RENTING INDUSTRIAL PROPERTIES</h1>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY RENT COMMERCIAL PROPERTIES WITH US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>WHY RENT INDUSTRIAL PROPERTIES WITH US</h2>
          <p className="mt-2">
            Welcome to Jay Housing Agency, your premier partner in finding the
            perfect industrial rental space for your business. Whether you're
            looking for a warehouse, manufacturing facility, or distribution
            center, our dedicated team is here to streamline the process and
            connect you with the ideal industrial property to meet your
            operational needs.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Extensive Property Portfolio</h5>
                  <p>
                    Explore our portfolio of industrial properties, ranging from
                    state-of-the-art warehouses to cutting-edge manufacturing
                    facilities. We have the right space for every industry.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Client-Centric Approach</h5>
                  <p>
                    Your satisfaction is our priority. We are committed to
                    understanding your business goals and finding a rental
                    solution that aligns seamlessly with your operational needs.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Industry Expertise</h5>
                  <p>
                    Benefit from our industry expertise and market knowledge. We
                    keep abreast of industrial real estate trends to provide you
                    with the most relevant and up-to-date information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>THE LEASING SERVICES WE OFFER</h2>
          <p className="mt-2">
            Ready to take your business to the next level? Contact Jay Housing
            Agency today, and let us help you find the perfect industrial
            property for rent. We are dedicated to making the leasing process
            efficient, transparent, and tailored to your business success.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Property Search and Selection</h5>
                  <p>
                    We work closely with you to understand your business
                    requirements and conduct a targeted search to present you
                    with industrial properties that meet your needs.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Lease Negotiation</h5>
                  <p>
                    We negotiate favorable lease terms on your behalf, ensuring
                    that you get the best possible agreement to suit your budget
                    and operational goals.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Due Diligence Assistance</h5>
                  <p>
                    Our experts guide you through the due diligence process,
                    helping you understand lease terms and regulations,before
                    committing to a rental agreement.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Customization Opportunities</h5>
                  <p>
                    Many of our industrial properties offer customization
                    options to tailor the space to your unique operational
                    requirements to ensure with your business needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>ACTIVE RENTAL LISTINGS</h2>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default IndustrialRenting;

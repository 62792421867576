const SERVER_URL =
  "https://jay-housing-agency-backend-nodejs-development-2mk6mheziq-uc.a.run.app/";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyAhA8D4GNYY4-4u8vinZJE1AZMUvBbn9Kw";
const IMAGE_URL = "https://storage.googleapis.com/kidsstreet-test/";

const amountConverter = (amountInRupees) => {
  return (amountInRupees / 10000000).toFixed(2);
};

const SET_FORMAT = (amount) => {
  var formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return formatter.format(amount);
};
module.exports = {
  SERVER_URL,
  YOUR_GOOGLE_MAPS_API_KEY,
  IMAGE_URL,
  amountConverter,
  SET_FORMAT,
};

import React from "react";
import Header from "./header";
import Footer from "./footer";
import { ToastContainer } from "react-toastify";
const LayoutTwo = ({ children }) => {
  return (
    <main>
      <Header />
      <div className="main">{children}</div>
      <ToastContainer />
      <Footer />
    </main>
  );
};

export default LayoutTwo;

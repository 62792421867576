import React from "react";
import CountUp from "react-countup";
import { motion } from "framer-motion";

const Counters = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="counters-section"
    >
      <div className="container">
        <div className="counter-box">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-6 border-right">
              <div className="box">
                <CountUp
                  duration={2.75}
                  className="value"
                  end={35}
                  suffix="+"
                />
                <p>Total years of experience</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-6 border-right">
              <div className="box">
                <CountUp
                  duration={2.75}
                  className="value"
                  end={5000}
                  suffix="+"
                />
                <p>Number of completed flat transactions</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-6 border-right">
              <div className="box">
                <CountUp
                  duration={2.75}
                  className="value"
                  end={700}
                  suffix="+"
                />
                <p>Number of commercial deals completed</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-6">
              <div className="box">
                <CountUp
                  duration={2.75}
                  className="value"
                  end={2000}
                  suffix="+"
                />
                <p>Leave and licenses completed</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Counters;

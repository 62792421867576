/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="banner"
    >
      <div className="bg-overlay">
        {/* BANNER */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="details"
              >
                <h1>
                  FIND YOUR DREAM HOUSE IN
                  <br />
                  <span>KANDIVALI, BORIVALI AND MALAD</span>
                </h1>
                <h5 className="subhead">
                  Unlocking dreams, opening doors. Your trusted partner in real
                  estate. Discover your perfect home with expert guidance and
                  unparalleled service. Welcome to seamless living!
                </h5>
                <div className="banner-buttons">
                  <Link to="/buying-residential">
                    <Button className="white-border-btn">
                      FIND YOUR DREAM HOME
                    </Button>
                  </Link>
                  <Link to="/contact-me">
                    <Button className="white-border-btn">
                      WORK WITH DINESH TRAMBADIA
                    </Button>
                  </Link>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default Banner;

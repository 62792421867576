/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};
const IndustrialBuying = () => {
  const categoryId = "65ca26086b0475e5c6a2e303"; // Residential
  const subCategoryId = "65ca26ad5f4799bc44a02b82"; // Buying

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/industrial-video.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>BUYING INDUSTRIAL PROPERTIES</h1>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY CHOOSE US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <div
            className="row"
            style={{ justifyContent: "space-between", alignItems: "center" }}
          >
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <h2>WHY CHOOSE US</h2>
            </div>
            <div className="col-xl-7 col-lg-7 col-sm-12">
              <p>
                Welcome to Jay Housing Agency, your trusted partner in acquiring
                industrial properties tailored to meet your business needs.
                Whether you're expanding your operations, relocating, or
                investing in a strategic industrial space, our dedicated team of
                real estate experts is here to guide you through every step of
                the process.
              </p>
            </div>
          </div>
          <div className="list-boxes mt-5">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Strategic Location</h5>
                  <p>
                    Industrial properties are strategically located to
                    facilitate efficient supply chain management. We understand
                    the importance of proximity to transportation hubs,
                    suppliers, and key markets for your business success.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Diverse Spaces for Every Industry</h5>
                  <p>
                    Our extensive portfolio includes a diverse range of
                    industrial spaces, from warehouses and distribution centers
                    to manufacturing facilities. No matter your industry, we
                    have the perfect space to meet your operational
                    requirements.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Customization and Flexibility</h5>
                  <p>
                    Industrial properties offer the flexibility to customize the
                    space according to your unique business needs. We will work
                    closely with you to identify opportunities and ensure the
                    property aligns perfectly with your requirements.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIUDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>TAILORED SERVICES FOR YOUR NEEDS</h2>
          <p className="mt-2">
            Investing in industrial properties is a strategic move for your
            business's growth and success. At Jay Housing Agency, we are
            committed to providing you with the expertise and personalized
            service needed to make informed decisions and secure the perfect
            industrial property for your business.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Property Search and Selection</h5>
                  <p>
                    Our experienced agents will conduct a thorough search based
                    on your criteria, ensuring we present you with industrial
                    properties that meet your specific requirements.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Market Analysis</h5>
                  <p>
                    We provide comprehensive market analyses, keeping you
                    informed about property values, and potential investment
                    opportunities in the industrial real estate sector.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Negotiation Expertise</h5>
                  <p>
                    Our skilled negotiators will work on your behalf to secure
                    the best possible terms, ensuring you get value for your
                    investment and a property that aligns with your goals.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Due Diligence Assistance</h5>
                  <p>
                    We guide you through the due diligence process, helping you
                    navigate legal and regulatory aspects to ensure a smooth
                    transaction.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Post-Purchase Support</h5>
                  <p>
                    We offer post-purchase support to address any questions or
                    concerns, ensuring a seamless transition into your new
                    industrial space.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>OUR FEATURED INDUSTRIAL LISTINGS</h2>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default IndustrialBuying;

/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { getPropertyDetails } from "../service/api";
import { IMAGE_URL, amountConverter } from "../config";
import PropertyLoadingBox from "../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  console.log(props);
  return (
    <Link
      to={props?.url ? props?.url : "/property-details"}
      style={{ textDecoration: "none" }}
    >
      <div className="property-box">
        <div className="property-image">
          <img
            src={props.thumbnailImg}
            className="thumbnail-img"
            alt={props.name}
          />
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};

const FeaturedListings = () => {
  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="featured-listings"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-12 col-sm-12">
            <div className="heading">
              <h2>EXPLORE THE FEATURED PROPERTIES</h2>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="row mt-4">
            <div className="col-xl-3 col-lg-3 col-sm-12">
              <PropertyLoadingBox />
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-12">
              <PropertyLoadingBox />
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-12">
              <PropertyLoadingBox />
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-12">
              <PropertyLoadingBox />
            </div>
          </div>
        ) : (
          <div className="row mt-4">
            <Swiper
              spaceBetween={30}
              breakpoints={{
                300: {
                  slidesPerView: 1,
                },
                400: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1000: {
                  slidesPerView: 4,
                },
              }}
              modules={[Scrollbar, Navigation, Pagination]}
              navigation={true}
              className="featured-swiper"
            >
              {propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <SwiperSlide key={index}>
                      {console.log("index", index)}
                      <div className="col-xl-12 col-lg-12 col-sm-12">
                        <PropertyBox
                          thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                          name={element?.propertyName}
                          builderName={element?.shortName}
                          area={
                            element?.locationDetails?.mainStreet +
                            ", " +
                            element?.locationDetails?.city
                          }
                          flatTypes={element?.floorPlans + " BHK Flats"}
                          price={element?.price}
                          url={"/property-details/" + element?._id}
                        />
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        )}
      </div>
    </motion.section>
  );
};

export default FeaturedListings;

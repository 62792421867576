import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const PropertyLoadingBox = () => {
  return (
    <div className="property-box">
      <div className="property-image">
        <Skeleton className="loading-skull" />
      </div>
      <div className="thumbnail-details">
        <Skeleton height={20} />
        <Skeleton height={20} width={80} />
        <Skeleton height={20} width={130} />
        <div className="house-details">
          <Skeleton height={20} width={150} />
          <Skeleton height={20} width={80} />
        </div>
        <Button className="visit-property-btn">View this property</Button>
      </div>
    </div>
  );
};

export default PropertyLoadingBox;

import { RiWhatsappFill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row footer-main">
          <div className="col-xl-12 col-sm-12">
            <h4 className="agent-name">JAY HOUSING AGENCY</h4>
          </div>
          <div className="col-xl-12 col-sm-12">
            <div className="agent-details">
              <div className="details-box">
                <h5>DINESH TRAMBADIA</h5>
                <p className="mt-2">
                  Rera No. <b>A 518000 91222</b>
                </p>
              </div>
              <div className="details-box">
                <h5>EMAIL ADDRESS</h5>
                <a href="mailto:jayhousingagency@gmail.com" className="mt-2">
                  jayhousingagency@gmail.com
                </a>
              </div>
              <div className="details-box">
                <h5>PHONE</h5>
                <div className="phone mt-2">
                  <RiWhatsappFill size={20} />
                  <a href="tel:+919322656348">+91 9322656348</a>
                </div>
                <div className="phone mt-2">
                  <RiWhatsappFill size={20} />
                  <a href="tel:+919773388720">+91 9773388720</a>
                </div>
              </div>
              <div className="details-box">
                <h5>ADDRESS</h5>
                <a
                  href="https://maps.app.goo.gl/TRvGtA6Ss8A3ova576"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-2"
                >
                  Shop No. 1, Radha Dalvi Nagar, Kandivali (East), Mumbai, 400
                  101
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-sm-12">
            <p
              className="footer-small-info mb-0"
              style={{ lineHeight: "20px" }}
            >
              Welcome to Jay Housing Agency, where we transform your real estate
              dreams into reality. Founded with a passion for connecting people
              with their ideal homes, we are a dynamic and client-centric real
              estate agency committed to providing top-notch service. At Jay
              Housing Agency, we understand that buying, selling, or renting a
              property is a significant milestone in your life. With a team of
              experienced and dedicated professionals, we guide you through
              every step of the real estate journey, ensuring a seamless and
              stress-free experience. Our mission is to redefine excellence in
              the real estate industry by delivering personalized solutions
              tailored to meet the unique needs of each client. Whether you are
              a first-time homebuyer, a seasoned investor, or a seller looking
              to maximize your property's value, Jay Housing Agency is your
              trusted partner. With an extensive portfolio of residential and
              commercial properties, we offer a diverse range of options to suit
              every taste and budget. Our commitment to integrity, transparency,
              and customer satisfaction sets us apart in the competitive real
              estate market.
            </p>
          </div>
        </div>
        <div className="copyright-main">
          <h5>
            © 2024, JAY HOUSING AGENCY. POWERED BY{" "}
            <a
              href="https://www.roundtechsquare.com"
              target="_blank"
              rel="noreferrer"
            >
              ROUNDTECHSQUARE
            </a>
          </h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import CountUp from "react-countup";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};
const CommercialBuying = () => {
  const categoryId = "65ca25f56b0475e5c6a2e300"; // Commercial
  const subCategoryId = "65ca26a85f4799bc44a02b7f"; // Buying

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/commercial-video.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>BUYING COMMERCIAL PROPERTIES</h1>
                <h2>
                  Welcome to Jay Housing Agency, where we specialize in helping
                  you make strategic investments in commercial real estate.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY CHOOSE US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="col-flex">
                <h2>WHY CHOOSE US</h2>
                <div className="counters">
                  <div className="row">
                    <div className="col-6 col-xl-6 col-lg-6 border-right">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={35}
                          suffix="+"
                        />
                        <p>Total years of experience</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={700}
                          suffix="+"
                        />
                        <p>Completed transactions</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-sm-12">
              <p>
                Commercial real estate offers a plethora of opportunities for
                investors seeking stable returns and long-term growth. Unlike
                residential properties, commercial spaces generate income
                through leases with businesses, providing a consistent cash
                flow. Additionally, the potential for appreciation over time
                makes commercial properties an attractive investment choice.
              </p>
              <p>
                Ready to embark on your commercial real estate journey? Contact
                us today to schedule a consultation with one of our experienced
                agents. Whether you're buying, selling, or leasing, we are
                committed to helping you achieve your commercial real estate
                goals. Trust Jay Housing Agency for expertise, reliability, and
                results in the dynamic world of commercial properties.
              </p>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIUDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>TAILORED SOLUTIONS FOR YOUR NEEDS</h2>
          <p className="mt-2">
            We believe that each client is unique, and so are their investment
            goals. Our agents work closely with you to understand your
            objectives, risk tolerance, and budget constraints. With this
            information, we tailor our approach to identify commercial
            properties that align with your vision and financial objectives.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Comprehensive Market Analysis</h5>
                  <p>
                    Our in-depth market analysis ensures that you are equipped
                    with the latest insights and trends in the commercial real
                    estate market. We provide detailed reports on property
                    values, rental rates, and market forecasts to empower you to
                    make informed investment decisions.
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Full-Service Support</h5>
                  <p>
                    From property identification to negotiation and closing, we
                    offer support throughout the entire transaction process. Our
                    team handles the paperwork, coordinates inspections, and
                    ensures that every detail is attended to, allowing you to
                    building a lucrative commercial real estate portfolio.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>OUR FEATURED COMMERCIAL LISTINGS</h2>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default CommercialBuying;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import CountUp from "react-countup";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};

const ResidentialSelling = () => {
  const categoryId = "65ca25db6b0475e5c6a2e2fb"; // Residential
  const subCategoryId = "65ca26fe5f4799bc44a02b91"; // Buying

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/general-banner-video-dinesh.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>SELLING RESIDENTIAL PROPERTIES</h1>
                <h2>
                  Welcome to Jay Housing Agency, your dedicated partner in
                  selling residential properties with ease and confidence.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY CHOOSE US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="col-flex">
                <h2>WHY CHOOSE US</h2>
                <div className="counters">
                  <div className="row">
                    <div className="col-6 col-xl-6 col-lg-6 border-right">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={35}
                          suffix="+"
                        />
                        <p>Total years of experience</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={5000}
                          suffix="+"
                        />
                        <p>Completed transactions</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-sm-12">
              <p>
                At Jay Housing Agency, we understand that selling your home is a
                significant decision. Our experienced team is committed to
                providing top-notch service, leveraging the latest marketing
                strategies, and utilizing our extensive network to ensure your
                property gets the attention it deserves.
              </p>
              <p>
                Ready to sell your residential property? Contact us today for a
                consultation. We will guide you through the process, provide a
                comprehensive market analysis, and help you set the right asking
                price to maximize the value of your home. Choose Jay Housing
                Agency for a seamless and successful selling experience. Your
                property deserves the best, and we're here to deliver
                outstanding results for you.
              </p>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>OUR SELLING APPROACH</h2>
          <p className="mt-2">
            Selling a home requires a strategic marketing plan, and we've got
            you covered. Our comprehensive approach includes professional
            photography, virtual tours, targeted online and offline advertising,
            and showcasing your property on our website and various other
            platforms. We know how to highlight your property's unique features
            to attract potential buyers.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Personalized Guidance</h5>
                  <p>
                    We are dedicated to understanding your goals meet your
                    specific needs. We provide expert guidance on pricing,
                    staging, and preparing your home for the market. We ensure
                    that your property stands out in the real estate landscape.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Negotiation Expertise</h5>
                  <p>
                    When it comes to negotiating the best deal for your
                    property, our seasoned professionals are at the forefront.
                    We work tirelessly to secure the best possible terms and
                    conditions, ensuring a smooth and favorable transaction for
                    you.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Transparent Communication</h5>
                  <p>
                    We believe in open and honest communication throughout the
                    selling process. You will be kept informed at every step,
                    from marketing updates to buyer feedback. Our goal is to
                    make sure you are well-informed during the entire selling
                    journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>LISTINGS ON THE MARKET</h2>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default ResidentialSelling;

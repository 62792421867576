/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};

const CommercialSelling = () => {
  const categoryId = "65ca25f56b0475e5c6a2e300"; // Commercial
  const subCategoryId = "65ca27025f4799bc44a02b94"; // Selling

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/commercial-video.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>SELLING COMMERCIAL PROPERTIES</h1>
                <h2>
                  Welcome to Jay Housing Agency, where we specialize in
                  maximizing the value of your commercial property investment.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY RENT COMMERCIAL PROPERTIES WITH US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>WHY SELL COMMERCIAL PROPERTIES WITH US</h2>
          <p className="mt-2">
            Choosing the right real estate agency to sell your commercial
            property is a critical decision. At Jay Housing Agency, we offer a
            comprehensive and strategic approach to selling commercial
            properties. Here's why you should partner with us:
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Market Expertise</h5>
                  <p>
                    Benefit from our in-depth understanding of the local
                    commercial real estate market. Our agents stay informed
                    about current market trends, property values, and buyer
                    preferences to position your property for optimal returns.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Customized Marketing Strategies</h5>
                  <p>
                    We employ targeted and innovative marketing strategies to
                    showcase your property to potential buyers. From online
                    platforms to traditional marketing channels, we leverage a
                    multi-faceted approach to reach a wide audience.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Network of Buyers</h5>
                  <p>
                    Tap into our extensive network of potential buyers,
                    including investors, developers, and businesses seeking
                    commercial spaces. Our connections allow us to market your
                    property to a diverse and qualified pool of prospects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>THE SELLING PROCESS SIMPLIFIED</h2>
          <p className="mt-2">
            Selling a commercial property involves various steps, and we are
            here to guide you through each stage of the process:
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Property Valuation</h5>
                  <p>
                    We conduct analysis to determine the fair market value of
                    your commercial property, ensuring you receive a competitive
                    and accurate assessment.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Strategic Marketing</h5>
                  <p>
                    We develop a tailored marketing plan to highlight the unique
                    features and benefits of your property, maximizing its
                    visibility in the market.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Negotiation</h5>
                  <p>
                    Skilled negotiators on work diligently to secure the best
                    possible deal for you, considering your financial goals and
                    the current market conditions.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Closing Assistance</h5>
                  <p>
                    We provide comprehensive support during the closing process,
                    managing paperwork, coordinating inspections, and ensuring a
                    smooth transaction.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>LISTINGS ON THE MARKET</h2>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default CommercialSelling;

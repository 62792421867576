/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import CountUp from "react-countup";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};

const ResidentialBuying = () => {
  const categoryId = "65ca25db6b0475e5c6a2e2fb"; // Residential
  const subCategoryId = "65ca269f5f4799bc44a02b7a"; // Buying

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/general-banner-video-dinesh.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>BUYING RESIDENTIAL PROPERTIES</h1>
                <h2>
                  Welcome to Jay Housing Agency, your trusted partner in finding
                  the perfect residential property for your dream home!
                </h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY CHOOSE US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="col-flex">
                <h2>WHY CHOOSE US</h2>
                <div className="counters">
                  <div className="row">
                    <div className="col-6 col-xl-6 col-lg-6 border-right">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={35}
                          suffix="+"
                        />
                        <p>Total years of experience</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={5000}
                          suffix="+"
                        />
                        <p>Completed transactions</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-sm-12">
              <p>
                At Jay Housing Agency, we understand that buying a home is one
                of the most significant financial decisions you'll ever make. We
                have a team of experienced and dedicated real estate agents is
                committed to providing personalized service, expert advice, and
                unparalleled market knowledge to help you make informed
                decisions.
              </p>
              <p>
                Discover a diverse range of residential properties in our
                extensive listings. From cozy starter homes to luxurious
                estates, we have a property to suit every lifestyle and budget.
                Our user-friendly website allows you to browse through available
                listings, filter search results based on your preferences, and
                even schedule property viewings at your convenience.
              </p>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIUDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>GUIDANCE EVERY STEP OF THE WAY</h2>
          <p className="mt-2">
            Buying a home involves a series of complex steps, from property
            search and financing to negotiations and closing. We are here to
            simplify the process for you. We offer guidance at every step,
            ensuring you have a seamless and stress-free experience. Trust us to
            negotiate the best possible deal and handle all the paperwork
            efficiently.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Local Expertise</h5>
                  <p>
                    As locals ourselves, we have a deep understanding of the
                    neighborhoods and communities we serve. Whether you're
                    looking for a property with top-rated schools, vibrant
                    cultural scenes, or proximity to essential amenities, we can
                    help you find the ideal location for your new home.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Financing Assistance</h5>
                  <p>
                    Navigating the world of mortgages and financing can be
                    overwhelming. We can connect you with trusted mortgage
                    professionals who will help you explore financing options
                    and find the best rates, ensuring that your dream home
                    remains within reach.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Transparent Communication</h5>
                  <p>
                    Communication is key in real estate transactions. We pride
                    ourselves on being transparent and keeping you informed
                    throughout the entire buying process. From property insights
                    to market trends, we'll ensure you have all the information
                    you need to make confident decisions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>EXPLORE RESIDENTIAL LISTINGS</h2>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12 h-full">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default ResidentialBuying;

import { motion } from "framer-motion";

const AboutDinesh = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="about"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-5 col-lg-5 col-sm-12">
            <img
              src={require("../assets/dinesh-trambadia-profile.webp")}
              alt="Dinesh Trambadia"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <h2>MEET DINESH TRAMBADIA</h2>
            <p>
              With an illustrious career spanning over three and a half decades
              in the vibrant real estate sector of Mumbai, Dinesh Trambadia
              stands as a paragon of experience and expertise. Since embarking
              on this journey over 35 years ago, he has navigated the intricate
              landscape of Mumbai's real estate market with unparalleled insight
              and precision.
            </p>
            <p>
              Throughout his extensive tenure, he has not only witnessed the
              evolution of Mumbai's skyline but has actively contributed to
              shaping it. The wealth of knowledge amassed over the years
              positions him as a trusted advisor, well-versed in the nuances of
              buying, selling, and investing in this dynamic market.
            </p>
            <p>
              Whether assisting first-time homebuyers, seasoned investors, or
              developers, he approach each transaction with a dedication to
              excellence, ensuring a seamless and successful real estate
              experience. In a city known for its competitive real estate
              landscape, Dinesh Trambadia remains the steadfast guide you can
              rely on for informed decisions and prosperous outcomes.
            </p>
          </div>
        </div>
        {/*  <div className="row align-items-center mt-5">
          <div className="col-xl-3 col-lg-3 col-sm-12">
            <img
              src={require("../assets/dinesh-trambadia-profile.webp")}
              className="small-image"
              alt="Dinesh Trambadia"
            />
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-12">
            <img
              src={require("../assets/dinesh-trambadia-profile.webp")}
              className="small-image"
              alt="Dinesh Trambadia"
            />
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-12">
            <img
              src={require("../assets/dinesh-trambadia-profile.webp")}
              className="small-image"
              alt="Dinesh Trambadia"
            />
          </div>
          <div className="col-xl-3 col-lg-3 col-sm-12">
            <img
              src={require("../assets/dinesh-trambadia-profile.webp")}
              className="small-image"
              alt="Dinesh Trambadia"
            />
          </div>
        </div> */}
      </div>
    </motion.section>
  );
};

export default AboutDinesh;

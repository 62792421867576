import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import { motion } from "framer-motion";

/* APIS */
import { contactUs } from "../../service/api";

/* STYLES */
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email().required("Email is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  message: yup.string().required("Message is required"),
});

const ContactMe = () => {
  const [loading, setLoading] = useState(false);

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      contactUs(values)
        .then((res) => {
          if (res?.status) {
            resetForm();
            console.log(res?.message);
            toast.success(res?.message);
          }
        })
        .catch((e) => {
          console.log("e-->", e);
          if (e?.response?.data?.message) {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
          resetForm();
        });
    },
  });

  /* META TITLE */
  const metaTitle = "Contact Me | Dinesh Trambadia";
  const metaDescription =
    "Reach out to me, your dedicated real estate agent, for personalized assistance in buying, selling, or renting properties.";
  const metaImage =
    "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", metaImage);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);

  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[name="twitter:image"]')
    .setAttribute("content", metaImage);

  return (
    <section className="contact-us">
     
     {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/general-banner-video-dinesh.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-sm-12">
                <h1>LET'S WORK TOGETHER</h1>
                <h2>Fill in the details to book an appointment.</h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* LOCATION MAP */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="details"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <form className="contact-form" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <div className="form-heading">
                      <h2>Drop us a line</h2>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        First Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${formik.errors.firstName && formik.touched.firstName
                            ? "validation-border"
                            : ""
                          }`}
                        placeholder="Name"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        name="firstName"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Last Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        className={`form-control ${formik.errors.lastName && formik.touched.lastName
                            ? "validation-border"
                            : ""
                          }`}
                        placeholder="Name"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        name="lastName"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Email <span>*</span>
                      </label>
                      <input
                        type="email"
                        className={`form-control ${formik.errors.email && formik.touched.email
                            ? "validation-border"
                            : ""
                          }`}
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        Phone <span>*</span>
                      </label>
                      <PhoneInput
                        placeholder="Phone Number"
                        type="text"
                        defaultCountry="IN"
                        name="phoneNumber"
                        value={formik.values.phoneNumber}
                        onChange={(phone) =>
                          formik.setFieldValue("phoneNumber", phone)
                        }
                        className={`phone-number-select ${formik.errors.phoneNumber && formik.touched.phoneNumber
                            ? "validation-border"
                            : ""
                          }`}
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <div className="form-group">
                      <label className="form-label">Message</label>
                      <textarea
                        rows="4"
                        className={`textarea-control ${formik.errors.message && formik.touched.message
                            ? "validation-border"
                            : ""
                          }`}
                        name="comments"
                        value={formik.values.message}
                        onChange={(message) =>
                          formik.setFieldValue("message", message.target.value)
                        }
                        placeholder="Short message"
                      />
                    </div>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-sm-12">
                    <Button type="submit" className="submit-btn">
                      {loading ? <Spinner size="sm" /> : "Submit Details"}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-xl-6 col-sm-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.8790717999677!2d72.85065767589357!3d19.20048344805148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7205f086a3d%3A0xd98d07f2645faa73!2sRadha%20Dalvi%20Nagar%2C%20MB%20Desai%20Rd%2C%20Kandivali%2C%20Govind%20Dalvi%20Nagar%2C%20Kandivali%20East%2C%20Mumbai%2C%20Maharashtra%20400101!5e0!3m2!1sen!2sin!4v1700458416081!5m2!1sen!2sin"
                title="Jay Housing Agency Office"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default ContactMe;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import CountUp from "react-countup";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};
const ResidentialRenting = () => {
  const categoryId = "65ca25db6b0475e5c6a2e2fb"; // Residential
  const subCategoryId = "65ca270f5f4799bc44a02b9a"; // Buying

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/general-banner-video-dinesh.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>RENTING RESIDENTIAL PROPERTIES</h1>
                <h2>
                  Whether you're exploring rental investment opportunities, our
                  team is here to make your renting experience smooth and
                  enjoyable.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY CHOOSE US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <div className="row" style={{ justifyContent: "space-between" }}>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="col-flex">
                <h2>WHY CHOOSE US</h2>
                <div className="counters">
                  <div className="row">
                    <div className="col-6 col-xl-6 col-lg-6 border-right">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={35}
                          suffix="+"
                        />
                        <p>Total years of experience</p>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-6">
                      <div className="box">
                        <CountUp
                          duration={2.75}
                          className="value"
                          end={5000}
                          suffix="+"
                        />
                        <p>Completed transactions</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-sm-12">
              <p>
                At Jay Housing Agency, we understand the importance of finding a
                rental property that feels like home. Our dedicated team is
                committed to providing personalized service, expert advice, and
                a wide range of rental options to meet your unique needs.
              </p>
              <p>
                Explore our extensive database of rental properties, featuring a
                variety of options from cozy apartments to spacious houses. Our
                user-friendly website allows you to search for rentals based on
                your preferences, including location, size, amenities, and
                budget. Finding the perfect place to call home has never been
                easier.
              </p>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>OUR APPROACH TO RENT PROPERTIES</h2>
          <p className="mt-2">
            Begin your search on our website or contact us for personalized
            assistance. We're here to help you navigate the rental market and
            find a property that fits your lifestyle.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Local Expertise</h5>
                  <p>
                    As locals ourselves, we have in-depth knowledge of the
                    neighborhoods and communities we serve. Whether you're
                    looking for a vibrant urban setting, a family-friendly
                    suburb, or a peaceful countryside retreat, we can guide you
                    to the rental property that aligns with your lifestyle and
                    priorities.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Transparent Communication</h5>
                  <p>
                    We believe in open and transparent communication. We will
                    keep you informed about available rental properties, market
                    trends, and any changes in the rental landscape. We want you
                    to feel confident and well-informed as you make decisions
                    about your rental journey.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Tenant Resources</h5>
                  <p>
                    As your trusted real estate partner, we provide valuable
                    resources for tenants, including information on local
                    utilities, schools, transportation, and community amenities.
                    Our goal is to ensure you have all the information you need
                    to make the right choice for your new home.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>RENTAL LISTINGS ON THE MARKET</h2>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default ResidentialRenting;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import { getPropertyDetails } from "../../../service/api";
import { useEffect, useState } from "react";
import { IMAGE_URL, amountConverter } from "../../../config";
import PropertyLoadingBox from "../../../components/property-loading-box";

/* PROPERTY BOX */
const PropertyBox = (props) => {
  return (
    <Link to={props?.url ? props?.url : "/"} style={{ textDecoration: "none" }}>
      <div className="property-box">
        <div className="property-image">
          {props.thumbnailImg && (
            <img
              src={props.thumbnailImg}
              className="thumbnail-img"
              alt={props.name}
            />
          )}
        </div>
        <div className="thumbnail-details">
          <h2 className="heading">{props.name}</h2>
          <h5 className="sub-heading">by {props.builderName}</h5>
          {/* <h5 className="sub-heading">{props.area}</h5> */}
          <div className="house-details">
            {/* <p className="area">{props.flatTypes}</p> */}
            <p className="price">
              <b>₹ {props.price}</b>
            </p>
          </div>
          <Link to={props?.url ? props?.url : "/property-details"}>
            <Button className="visit-property-btn">View this property</Button>
          </Link>
        </div>
      </div>
    </Link>
  );
};

const CommercialRenting = () => {
  const categoryId = "65ca25f56b0475e5c6a2e300"; // Commercial
  const subCategoryId = "65ca27135f4799bc44a02b9d"; // Renting

  // State Variables
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [propertyList, setPropertyList] = useState([]);

  const getPropertyDetailsFunction = () => {
    const obj = {
      page,
      sizePerPage,
      category: categoryId,
      subCategory: subCategoryId,
    };
    setLoading(true);
    getPropertyDetails(obj)
      .then((res) => {
        if (res?.status) {
          setPropertyList(res?.data?.docs);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPropertyDetailsFunction();
  }, []);

  return (
    <section className="property-type">
      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <video autoPlay loop muted>
          <source
            src="https://storage.googleapis.com/cdn-amberwoodre/cdn-video-assets/commercial-video.mp4"
            type="video/mp4"
          />
        </video>
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>RENTING COMMERCIAL PROPERTIES</h1>
                <h2>
                  Contact us today to schedule a consultation with us. At
                  Housing Agency, we're dedicated to helping your business
                  thrive by finding the right commercial property to support
                  your success.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* WHY RENT COMMERCIAL PROPERTIES WITH US */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>WHY RENT COMMERCIAL PROPERTIES WITH US</h2>
          <p className="mt-2">
            Choosing the right commercial space is crucial for the success of
            your business. At Jay Housing Agency, we understand the unique needs
            of businesses across various industries. Here's why you should
            consider renting commercial properties with us.
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Tailored Solutions</h5>
                  <p>
                    We work closely with you to understand your business
                    requirements, ensuring that the properties we recommend
                    align with your vision, budget, and growth plans.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Extensive Inventory</h5>
                  <p>
                    Explore our diverse portfolio of commercial properties which
                    allows you to choose from a range of options to find the
                    space that suits your business needs.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="box">
                  <h5>Local Expertise</h5>
                  <p>
                    We have in-depth knowledge of the local commercial real
                    estate market. We stay on the top of of market trends,
                    rental rates to provide you with accurate information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* GUIDANCE */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-details"
      >
        <div className="container">
          <h2>THE LEASING PROCESS MADE SIMPLE</h2>
          <p className="mt-2">
            Renting a commercial property can be a complex process, but with Jay
            Housing Agency, it's hassle-free. We are committed to making the
            leasing process seamless and efficient. Here's how we can assist
            you:
          </p>
          <div className="list-boxes">
            <div className="row gy-4 justify-content-center">
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Property Selection</h5>
                  <p>
                    We assist you in identifying commercial spaces that meet
                    your criteria, whether you prioritize location, size, or
                    budget.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Negotiation</h5>
                  <p>
                    Our experienced negotiators work on your behalf to secure
                    favorable lease terms, ensuring that you get best deal.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Paperwork and Compliance</h5>
                  <p>
                    We handle the paperwork and guide you through the legal
                    aspects of leasing to ensure a compliant transaction.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="box">
                  <h5>Post-Lease Support</h5>
                  <p>
                    We're here to support you post-lease, addressing concerns or
                    communication between tenants and landlords.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PROPERTIES LISTING */}
      {propertyList?.length !== 0 && (
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="property-listing"
        >
          <div className="container">
            <h2>ACTIVE RENTAL LISTINGS</h2>
            <p>
              Begin your search for the ideal commercial space by exploring our
              curated list of available properties. From vibrant urban office
              spaces to strategically located retail storefronts, we have
              options that cater to a variety of business needs.
            </p>

            <div className="row">
              {loading ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                  <div className="col-xl-3 col-lg-3 col-sm-12">
                    <PropertyLoadingBox />
                  </div>
                </>
              ) : (
                propertyList &&
                propertyList?.length > 0 &&
                propertyList?.map((element, index) => {
                  return (
                    <div className="col-xl-3 col-lg-3 col-sm-12">
                      <PropertyBox
                        thumbnailImg={IMAGE_URL + element?.images?.[0]?.url}
                        name={element?.propertyName}
                        builderName={element?.shortName}
                        area={
                          element?.locationDetails?.mainStreet +
                          ", " +
                          element?.locationDetails?.city
                        }
                        flatTypes={element?.floorPlans + " BHK Flats"}
                        price={element?.price}
                        url={"/property-details/" + element?._id}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </motion.div>
      )}
    </section>
  );
};

export default CommercialRenting;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import parse from "html-react-parser";
import { useFormik } from "formik";
import * as yup from "yup";
import PhoneInput from "react-phone-number-input";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { getPropertyDetailsById, contactUs } from "../../service/api";
import { IMAGE_URL, SET_FORMAT } from "../../config";

/* VALIDATION SCHEMA */
const validationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().email().required("Email is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  message: yup.string().required("Message is required"),
});

const PropertyDetails = () => {
  // Global Variables
  const params = useParams();

  // State Variables
  const [loading, setLoading] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState(null);

  // Functions
  const getProductDetailsFunction = () => {
    if (params?.id) {
      getPropertyDetailsById(params?.id).then((res) => {
        if (res?.status) {
          setPropertyDetails(res?.data);
          formik.setFieldValue("propertyId", res?.data?._id);
        }
      });
    }
  };

  /* FORM VALUES HANDLING */
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
      propertyId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      contactUs(values)
        .then((res) => {
          if (res?.status) {
            resetForm();
            console.log(res?.message);
            toast.success(res?.message);
          }
        })
        .catch((e) => {
          console.log("e-->", e);
          if (e?.response?.data?.message) {
            setLoading(false);
            toast.error(e?.response?.data?.message);
          }
        })
        .finally((res) => {
          setLoading(false);
          resetForm();
        });
    },
  });

  // UseEffects
  useEffect(() => {
    if (params?.id) {
      getProductDetailsFunction();
    }
  }, [params]);

  console.log("PROPERTY DETAILS: ", propertyDetails);

  return (
    <section className="new-property-detail">
      <div className="container">
        {/* PROPERTY DETAILS */}
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="details-header"
        >
          <div className="address">
            <div>
              <h5>{propertyDetails?.propertyName}</h5>
              <p>
                {propertyDetails?.locationDetails?.houseNumber}{" "}
                {propertyDetails?.locationDetails?.buildingApartmentName},{" "}
                {propertyDetails?.locationDetails?.mainStreet},{" "}
                {propertyDetails?.locationDetails?.city},{" "}
                {propertyDetails?.locationDetails?.state} -{" "}
                {propertyDetails?.locationDetails?.country}
              </p>
            </div>
          </div>
          <div className="features">
            {/* PRICE */}
            {propertyDetails?.price && (
              <div className="tile">
                <p className="value">{propertyDetails?.price}</p>
                <p className="label">Price</p>
              </div>
            )}

            {/* FLOOR PLAN */}
            {propertyDetails?.floorPlans && (
              <div className="tile">
                <p className="value">{propertyDetails?.floorPlans}</p>
                <p className="label">BHK Flats</p>
              </div>
            )}

            {/* LOT SIZE */}
            {propertyDetails?.landSize && (
              <div className="tile">
                <p className="value">{propertyDetails?.landSize}</p>
                <p className="label">Carpet Area</p>
              </div>
            )}
          </div>
        </motion.div>

        {/* PROPERTY PHOTOS AND DESCRIPTION */}
        <div className="row">
          {/* LEFT SECTION */}
          <div className="col-xl-8 col-sm-12">
            <div className="page-left">
              {propertyDetails?.images?.length > 0 ? (
                <Swiper
                  modules={[Scrollbar, Navigation, Pagination]}
                  navigation={true}
                  slidesPerView={1}
                  spaceBetween={30}
                  className="property-photos"
                >
                  {propertyDetails?.images?.map((element, index) => {
                    return (
                      <SwiperSlide className="img-slider" key={index}>
                        <img
                          src={IMAGE_URL + element?.url}
                          className="property-img"
                          alt="61109 Via Vicenza San Ramon CA"
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              ) : (
                <p className="label">No Images Found</p>
              )}

              {/* DESCRIPTION */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="description"
              >
                {parse(
                  propertyDetails?.additionalFeatures?.description
                    ? propertyDetails?.additionalFeatures?.description
                    : ""
                )}
              </motion.div>

              {/* PROPERTY LOCATION */}
              {/* <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="property-details"
              >
                <h4>PROPERTY LOCATION</h4>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3767.7486426270457!2d72.8435458!3d19.2061779!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b79ca4b85fdf%3A0x6ef9bb6ee6e729c8!2sLa%20Serena!5e0!3m2!1sen!2sin!4v1700479088010!5m2!1sen!2sin"
                  title="Property Location"
                  className="location-map"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </motion.div> */}

              {/* FLOOR PLANS */}
              {propertyDetails?.floorPlansList?.length !== 0 && (
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  transition={{ duration: 0.3 }}
                  variants={{
                    visible: { opacity: 1 },
                    hidden: { opacity: 0 },
                  }}
                  className="floor-plans"
                >
                  <h4>FLOOR PLANS</h4>
                  <div className="row">
                    {propertyDetails?.floorPlansList?.map((element, index) => {
                      return (
                        <div
                          key={index}
                          className="col-xl-4 col-lg-4 col-sm-12"
                        >
                          <div className="floor-plan-box">
                            <div className="details">
                              <h5 className="head">{element?.floorName}</h5>
                              <h5 className="subhead">{element?.floorSize}</h5>
                              {/* <h5 className="price">₹ {element?.floorPrice}</h5> */}
                            </div>
                            <div className="image">
                              <img
                                src={IMAGE_URL + element?.image?.url}
                                alt="2 BHK Floor Plan"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </motion.div>
              )}

              {/* PROPERTY AMENITIES */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="property-indetail"
              >
                <h4>AMENITIES</h4>
                <div className="amenities-list">
                  {propertyDetails?.amenities?.length > 0
                    ? propertyDetails?.amenities?.map((element, index) => {
                        return (
                          <div className="list-box" key={index}>
                            <p>{element}</p>
                          </div>
                        );
                      })
                    : "No Amenities Found"}
                </div>
              </motion.div>

              {/* PROPERTY FEATURES */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="property-indetail"
              >
                <h4>FEATURES</h4>
                <div className="amenities-list">
                  {propertyDetails?.features?.length > 0
                    ? propertyDetails?.features?.map((element, index) => {
                        return (
                          <div className="list-box" key={index}>
                            <p>{element}</p>
                          </div>
                        );
                      })
                    : "No Features Found"}
                </div>
              </motion.div>

              {/* PROPERTY NEAR BY PLACES */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="property-indetail"
              >
                <h4>NEAR BY PLACES</h4>
                <div className="amenities-list">
                  {propertyDetails?.nearByPlaces?.length > 0
                    ? propertyDetails?.nearByPlaces?.map((element, index) => {
                        return (
                          <div className="list-box" key={index}>
                            <p>{element?.place}</p>
                          </div>
                        );
                      })
                    : "No Features Found"}
                </div>
              </motion.div>

              {/* ABOUT THE AREA */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="about-area"
              >
                <h4>ABOUT THE AREA</h4>
                {parse(
                  propertyDetails?.additionalFeatures?.aboutProperty
                    ? propertyDetails?.additionalFeatures?.aboutProperty
                    : ""
                )}
              </motion.div>
            </div>
          </div>

          {/* RIGHT SECTION */}
          <div className="col-xl-4 col-sm-12">
            <div className="page-right">
              {/* DETAILS */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="official-details"
              >
                <p className="last-updated">
                  MahaRERA Registration No:{" "}
                  {propertyDetails?.registrationNumber}
                </p>
                {/* <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Status</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">Under construction</p>
                    </div>
                  </div>
                </div> */}

                {/* PROJECT SIZE */}
                {propertyDetails?.projectSize && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Project size</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">
                          {propertyDetails?.projectSize} acres
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* LAUNCH DATE */}
                {propertyDetails?.launchDate && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Launch Date</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">{propertyDetails?.launchDate}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* POSSESSION BY */}
                {propertyDetails?.possessionBy && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Possession by</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">{propertyDetails?.possessionBy}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* TOTAL UNITS */}
                {propertyDetails?.totalUnits && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Total units</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">{propertyDetails?.totalUnits}</p>
                      </div>
                    </div>
                  </div>
                )}

                {propertyDetails?.totalTowers && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Total towers</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">{propertyDetails?.totalTowers}</p>
                      </div>
                    </div>
                  </div>
                )}

                {propertyDetails?.category?.name && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Project Type</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">
                          {propertyDetails?.category?.name}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {propertyDetails?.propertyType && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Property Type</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">{propertyDetails?.propertyType}</p>
                      </div>
                    </div>
                  </div>
                )}

                {propertyDetails?.shortName && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Builder Name</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">{propertyDetails?.shortName}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="details-box">
                  <div className="row">
                    <div className="col-xl-5 col-6">
                      <p className="label">Occupancy Certificate</p>
                    </div>
                    <div className="col-xl-7 col-6">
                      <p className="value">
                        {propertyDetails?.OccupancyCertificate}
                      </p>
                    </div>
                  </div>
                </div> */}

                {propertyDetails?.locationDetails?.postalCode && (
                  <div className="details-box">
                    <div className="row">
                      <div className="col-xl-5 col-6">
                        <p className="label">Pincode</p>
                      </div>
                      <div className="col-xl-7 col-6">
                        <p className="value">
                          {propertyDetails?.locationDetails?.postalCode}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>

              {/* CONTACT DINESH TRAMBADIA */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="contact-compass-agent"
              >
                <h5>CONTACT JAY HOUSING AGENCY</h5>
                <form className="contact-form" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.firstName && formik.touched.firstName
                              ? "validation-border"
                              : ""
                          }`}
                          placeholder="Name"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          name="firstName"
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className={`form-control ${
                            formik.errors.lastName && formik.touched.lastName
                              ? "validation-border"
                              : ""
                          }`}
                          placeholder="Name"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          name="lastName"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <input
                          type="email"
                          className={`form-control ${
                            formik.errors.email && formik.touched.email
                              ? "validation-border"
                              : ""
                          }`}
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          placeholder="Email Address"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <PhoneInput
                          placeholder="Phone Number"
                          type="text"
                          defaultCountry="IN"
                          name="phoneNumber"
                          value={formik.values.phoneNumber}
                          onChange={(phone) =>
                            formik.setFieldValue("phoneNumber", phone)
                          }
                          className={`phone-number-select ${
                            formik.errors.phoneNumber &&
                            formik.touched.phoneNumber
                              ? "validation-border"
                              : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <textarea
                          rows="4"
                          className={`textarea-control ${
                            formik.errors.message && formik.touched.message
                              ? "validation-border"
                              : ""
                          }`}
                          name="comments"
                          value={formik.values.message}
                          onChange={(message) =>
                            formik.setFieldValue(
                              "message",
                              message.target.value
                            )
                          }
                          placeholder="Short message"
                        />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <Button type="submit" className="submit-btn">
                        Send Message
                      </Button>
                    </div>
                  </div>
                </form>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PropertyDetails;
